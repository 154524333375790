import React from 'react';
import styled from 'styled-components';

import tefalPng from '../../../../../asset/tefal/tefal-logo.png';
import bigLogoPng from '../../../../../asset/tefal/big-logo.png';
import duetLogoPng from '../../../../../asset/tefal/duet-logo.png';

export const Hero: React.FC<{}> = ({}) => {
  return (
    <Container>
      <LogoStyled src={bigLogoPng} />
      <DuetStyled src={duetLogoPng} />
      <TefalStyled src={tefalPng} />
    </Container>
  );
};

const DuetStyled = styled.img`
  height: 118px;
  width: 640px;

  @media screen and (max-width: 900px) {
    width: 480px;
    height: auto;
  }

  @media screen and (max-width: 720px) {
    width: 320px;
  }
`;

const TefalStyled = styled.img`
  height: 84px;
  width: 400px;

  @media screen and (max-width: 900px) {
    width: 320px;
    height: auto;
  }

  @media screen and (max-width: 720px) {
    width: 240px;
  }
`;

const LogoStyled = styled.img`
  height: 149px;
  width: 400px;

  @media screen and (max-width: 900px) {
    width: 320px;
    height: auto;
  }

  @media screen and (max-width: 720px) {
    width: 240px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  align-items: center;
  gap: 20px;
`;
