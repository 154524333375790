import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { Footer } from '../../container/footer';
import { Header } from '../../container/header/header';
import { RegistarionButton } from '../../../../common/registration-button';
import { i18n } from '../../../../lib/lang';

import BackgroundImage from '../../../../asset/img/summer/background.png';

import WinPrize from '../../../../asset/img/summer/prize-photo.png';
import WinPrizeMobile from '../../../../asset/img/summer/prize-photo-mobile.png';

export const Page: React.FC<RouteComponentProps> = () => {
  const winHrefFirst = i18n.t('SUMMER.WINNER.WIN1');
  const winHrefSecond = i18n.t('SUMMER.WINNER.WIN2');
  const winHrefThird = i18n.t('SUMMER.WINNER.WIN3');
  const winHrefFourth = i18n.t('SUMMER.WINNER.WIN4');
  const winHrefFifth = i18n.t('SUMMER.WINNER.WIN5');

  return (
    <Wrapper>
      <Layout>
        <Header />
        <Container>
          <ButtonContainer>
            <RegistarionButton
              isTargetBlank
              href={winHrefFirst}
              tid="SUMMER.WINNER.WINNER_LIST.FIRST"
            />
            <RegistarionButton
              isTargetBlank
              href={winHrefSecond}
              tid="SUMMER.WINNER.WINNER_LIST.SECOND"
            />
            <RegistarionButton
              isTargetBlank
              href={winHrefThird}
              tid="SUMMER.WINNER.WINNER_LIST.THIRD"
            />
            <RegistarionButton
              isTargetBlank
              href={winHrefFourth}
              tid="SUMMER.WINNER.WINNER_LIST.FOURTH"
            />
            <RegistarionButton
              isTargetBlank
              href={winHrefFifth}
              tid="SUMMER.WINNER.WINNER_LIST.FIFTH"
            />
          </ButtonContainer>
          <PrizeContainer>
            <PrizeImg src={WinPrize} alt="prize photo" />
            <PrizeImgMobile src={WinPrizeMobile} alt="prize photo" />
          </PrizeContainer>
        </Container>
        <Footer />
      </Layout>
    </Wrapper>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  button {
    width: 100%;
  }
`;

const PrizeImg = styled.img`
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
const PrizeImgMobile = styled.img`
  height: 660px;
  width: auto;

  @media screen and (max-width: 500px) {
    height: 460px;
  }

  @media screen and (min-width: 600px) {
    display: none;
  }
`;

const PrizeContainer = styled.div`
  position: relative;
  border-radius: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;

  gap: 100px;
`;

const Wrapper = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fcee19;
  height: max-content;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  overflow-x: hidden;
  gap: 200px;
  background-image: url(${BackgroundImage});
  background-size: cover;

  @media screen and (max-width: 1100px) {
    gap: 115px;
  }

  @media screen and (max-width: 720px) {
    gap: 100px;
  }
`;
