import React from 'react';

import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Switch } from 'react-router';

import { WINNERS_PAGE_PAGE_PATH, WinnersPage } from '../winner';
import { TEFAL_PAGE_PATH, TefalPage } from '../main';

export const Page: React.FC = () => {
  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Switch>
          <Route path={WINNERS_PAGE_PAGE_PATH} component={WinnersPage} />
          <Route path={TEFAL_PAGE_PATH} component={TefalPage} />
        </Switch>
      </IonRouterOutlet>
    </IonReactRouter>
  );
};
