import React from 'react';
import styled from 'styled-components';

import { TextElem } from '../../../../common/text';
import { i18n } from '../../../../lib/lang';
import LogoPng from '../../../../asset/tefal/logo.png';

export const Header: React.FC<{}> = ({}) => {
  const mainHref = i18n.t('TEFAL.MENU.ICON_HREF');
  const ruleHref = i18n.t('TEFAL.RULE.BUTTON_HREF');

  return (
    <Wrapper>
      <Container>
        <LinkStyled target="_blank" href={mainHref}>
          <TitleImg src={LogoPng} />
        </LinkStyled>
        <MobileContainer>
          <TitleImgMobile src={LogoPng} />
          <LinkStyled target="_blank" href={ruleHref}>
            <TextRuleMobile tid="TEFAL.HOME.RULE" color="white" type="medium" />
          </LinkStyled>
        </MobileContainer>

        <DateContainer>
          <TextElem
            tid="TEFAL.HOME.DATE_TITLE"
            color="black"
            type="regular"
            size="default"
          />
          <TextElem
            tid="TEFAL.HOME.DATE"
            color="scarlet"
            type="medium"
            size="main"
          />
        </DateContainer>
        <LinkStyled target="_blank" href={ruleHref}>
          <TextRule
            tid="TEFAL.HOME.RULE"
            color="white"
            type="medium"
            size="heading"
          />
        </LinkStyled>
      </Container>
    </Wrapper>
  );
};

const MobileContainer = styled.div`
  width: 100%;
  display: none;
  align-items: center;

  @media screen and (max-width: 720px) {
    display: flex;
    justify-content: space-between;
  }
`;

const TextRule = styled(TextElem)`
  @media screen and (max-width: 720px) {
    display: none;
  }
`;

const TextRuleMobile = styled(TextElem)`
  font-size: 16px;
  display: none;

  @media screen and (max-width: 720px) {
    display: block;
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #ffffff;
  padding: 16px 40px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  position: relative;
  left: 45px;

  @media screen and (max-width: 720px) {
    padding: 12px;
    left: 0px;
    width: 100%;
  }
`;

const LinkStyled = styled.a`
  transition: 0.3s opacity ease;
  text-decoration: none;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 20px;
  z-index: 2;

  @media screen and (max-width: 520px) {
    padding: 25px 20px;
  }
`;

const TitleImg = styled.img`
  width: 128px;
  height: 55px;

  @media screen and (max-width: 720px) {
    display: none;
  }
`;

const TitleImgMobile = styled(TitleImg)`
  display: none;
  width: 107px;
  height: 40px;

  @media screen and (max-width: 720px) {
    display: block;
  }
`;

const Container = styled.header`
  display: flex;
  width: 100%;
  max-width: 1200px;
  align-items: center;
  justify-content: space-between;
  z-index: 2;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    gap: 24px;
  }
`;
